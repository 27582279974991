// -----------------------------------------------------------------------------
// Libraries
// -----------------------------------------------------------------------------
import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "antd/lib/modal";

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { Button } from "components/Common/Button";
import { Paragraph3, Heading4 } from "themes/default/_typography";

// -----------------------------------------------------------------------------
// Styles and utils
// -----------------------------------------------------------------------------
import useLogout from "utils/useLogout";
import StyledDeleteUserModal from "./styles";
import { Flex, notification } from "antd";
import { useDeleteUserMutation } from "store/api/api";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const DeleteUserModal = ({ open, setOpen }) => {
	const { gym } = useParams();
	const navigate = useNavigate();
	const logoutHook = useLogout(navigate, gym);
	const [deleteUserTrigger, deleteUser] = useDeleteUserMutation();

	const handleDeleteUser = useCallback(async () => {
		try {
			await deleteUserTrigger().unwrap();
			notification.success({
				message: "User Deleted successfully",
			});
			setTimeout(() => {
				logoutHook.logout();
			}, 1000);
		} catch (error) {
			notification.error({
				message: "Error",
				description: "There was an error deleting your account. Please try again later.",
			});
		}
	}, [deleteUserTrigger, logoutHook]);

	const handleCloseModal = useCallback(() => {
		setOpen(false);
	}, [setOpen]);

	return (
		<Modal title="Confirm" open={open} onCancel={handleCloseModal} footer={null} maskClosable={false}>
			<StyledDeleteUserModal className="sb-delete-user-modal">
				<div className="sb-delete-user-modal__text">
					<Heading4>Please confirm you want to delete this account. This can't be undone</Heading4>
				</div>
				<Flex className="sb-confirm-form__actions">
					<Button className="primary-filled" onClick={handleCloseModal}>
						<Paragraph3>Cancel</Paragraph3>
					</Button>
					<Button
						loading={deleteUser.isLoading}
						className="primary-outlined primary-outlined--delete"
						onClick={handleDeleteUser}
					>
						<Paragraph3>Delete</Paragraph3>
					</Button>
				</Flex>
			</StyledDeleteUserModal>
		</Modal>
	);
};

export default DeleteUserModal;
