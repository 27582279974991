// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React, { useState, useEffect } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import { Button } from "components/Common/Button";
import { LoadingMask } from "components/Common/LoadingMask";
import { Paragraph2 } from "themes/default/_typography";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import StyledStripeForm from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userAtom } from "globalAtoms";
import { useSelector, useDispatch } from "react-redux";
import { selectSelectedProduct } from "store/selectors";
import { updateCurrentMembershipDetails } from "store/slices";
import {
	useCreateSubscriptionMutation,
	useStripeCreateSubscriptionToPlatformMutation,
	useGetCurrentUserQuery,
	useGetDomainGymQuery,
	useStripeUpdateCustomerDefaultPaymentMethodMutation,
	useStripeCreateSubscriptionPriceMutation,
	useStripeGetSubscriptionsForGymMutation,
	useStripeUpdateMembershipPricingMutation,
} from "store/api/api";
import { InfoCircleOutlined } from "@ant-design/icons";
import { notification } from "antd";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const StripeForm = ({
	origin,
	buttonText,
	afterSubscribe,
	clientSecret,
	subscribingToGym,
	update,
	currentCustomerId,
}) => {
	const navigate = useNavigate();
	const stripe = useStripe();
	const elements = useElements();
	const dispatch = useDispatch();
	const [message, setMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [connectedAccountId, setConnectedAccountId] = useState(null);
	const user = useRecoilValue(userAtom);
	const token = localStorage.getItem("creator_jwt_token");
	const split = token.split(".")[1];
	const formatted = JSON.parse(atob(split));
	const JWTID = formatted.id;
	const { data: currentUserData, refetch: syncCurrentUser } = useGetCurrentUserQuery({ user_id: JWTID });
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const selectedProduct = useSelector(selectSelectedProduct);
	const [subscribeToGymTrigger, subscribeToGymResponse] = useCreateSubscriptionMutation();
	const [subscribeToPlatformTrigger] = useStripeCreateSubscriptionToPlatformMutation();
	const [updateCustomerDefaultPaymentMethodTrigger] = useStripeUpdateCustomerDefaultPaymentMethodMutation();
	const [createSubscriptionPriceTrigger] = useStripeCreateSubscriptionPriceMutation();
	const [getSubscriptionsForGymTrigger] = useStripeGetSubscriptionsForGymMutation();
	const [updateMembershipPricingTrigger, updateMembershipPricingResponse] = useStripeUpdateMembershipPricingMutation();

	useEffect(() => {
		if (user?.gym?.stripe_info?.stripe_connected_account_id) {
			setConnectedAccountId(user.gym.stripe_info.stripe_connected_account_id);
		}
	}, [user]);

	useEffect(() => {
		if (subscribeToGymResponse.isSuccess) {
			notification.success({
				message: "Successfully subscribed!",
			});
		} else if (subscribeToGymResponse.isError) {
			notification.error({
				message: "Error subscribing. Please try again later.",
			});
			console.log("error subscribing to gym:", subscribeToGymResponse);
		}
	}, [subscribeToGymResponse]);

	useEffect(() => {
		if (updateMembershipPricingResponse.isSuccess) {
			const newMembershipDetails = {
				id: updateMembershipPricingResponse?.data?.product,
				product: updateMembershipPricingResponse?.data?.product,
				unit_amount: updateMembershipPricingResponse?.data?.unit_amount,
				type: updateMembershipPricingResponse?.data?.type,
				membersPay: updateMembershipPricingResponse?.data?.unit_amount / 100,
				default_price: updateMembershipPricingResponse?.data?.id,
			};
			dispatch(updateCurrentMembershipDetails({ currentMembershipDetails: newMembershipDetails }));
		}
	}, [dispatch, updateMembershipPricingResponse]);

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (!stripe || !elements) {
			return;
		}

		setIsLoading(true);
		try {
			const res = await stripe.confirmSetup({
				elements,
				redirect: "if_required",
			});
			if (res.error) {
				setIsLoading(false);
				setMessage(res.error.message);
				notification.error({
					message: res?.error?.message || "An error occured during subscription",
				});
			} else {
				await handleGetSetup();
			}
		} catch (error) {
			if (error.type === "card_error" || error.type === "validation_error") {
				setMessage("An unexpected error occurred. Please try again or contact support at ");
			} else {
				setMessage("An unexpected error occurred.");
			}
		}

		// if(setup intent goes goes through properly, update user default payment method)

		setIsLoading(false);
	};

	const handleGetSetup = async () => {
		try {
			syncCurrentUser();
			const res = await stripe.retrieveSetupIntent(clientSecret);
			// response should have the new payment method
			const paymentMethod = res.setupIntent.payment_method;
			// we then attatch this to the customer with this call

			const userCustomerId =
				currentCustomerId || subscribingToGym?.customerId || user?.customer_id || currentUserData?.customer_id || null;

			await updateCustomerDefaultPaymentMethodTrigger({
				customer_id: userCustomerId,
				payment_method_id: paymentMethod,
			}).unwrap();
			if (update) {
				return afterSubscribe?.();
			}

			if (subscribingToGym) {
				const body = {
					customer_id: userCustomerId,
					price_id: subscribingToGym?.priceId,
					account_id: subscribingToGym?.accountId,
					gym_id: domainGymResponse?.data?.id,
				};
				const { data, error } = await subscribeToGymTrigger(body);
				if (data) {
					notification.success({
						message: "Subscription successful",
					});
					if (afterSubscribe) {
						afterSubscribe();
					} else {
						navigate(-1);
					}
				}
				if (error) {
					notification.error({
						message: error.data?.error || "An error occured during subscription",
					});
				}
			} else {
				const { data, error } = await subscribeToPlatformTrigger({
					account_id: connectedAccountId,
					customer_id: currentCustomerId ? currentCustomerId : user.customer_id,
					product_name: selectedProduct?.productName,
				});

				const gymProductResponse = await getSubscriptionsForGymTrigger({
					gym_name: domainGymResponse?.data?.name,
				})
					.unwrap()
					.catch(() => null);

				const gymProductPrice =
					gymProductResponse && !isNaN(Number(gymProductResponse?.unit_amount))
						? Number(gymProductResponse.unit_amount)
						: null;

				// If there is no product for the gym, create one
				// If product exists but price below the minimum, update the price to the minimum
				// If product exists and price is above the minimum, use the existing product
				if (!gymProductResponse) {
					const priceSubscription = await createSubscriptionPriceTrigger({
						account_id: connectedAccountId,
						product_name: `Monthly: ${domainGymResponse?.data?.name}`,
						price: selectedProduct.minMembersPrice * 100,
					}).unwrap();

					const currentMembershipDetails = {
						id: priceSubscription.id,
						product: priceSubscription.id,
						unit_amount: priceSubscription.default_price.unit_amount,
						type: priceSubscription.type,
						membersPay: priceSubscription.default_price.unit_amount / 100,
						default_price: priceSubscription.default_price.id,
					};

					dispatch(updateCurrentMembershipDetails({ currentMembershipDetails }));
				} else if (gymProductPrice < selectedProduct?.minMembersPrice * 100) {
					await updateMembershipPricingTrigger({
						product_id: gymProductResponse.id,
						new_price: selectedProduct.minMembersPrice * 100,
					}).unwrap();
				} else {
					const currentMembershipDetails = {
						id: gymProductResponse.id,
						product: gymProductResponse.id,
						unit_amount: gymProductResponse.unit_amount,
						type: gymProductResponse.type,
						membersPay: gymProductResponse.unit_amount / 100,
						default_price: gymProductResponse.default_price,
					};

					dispatch(updateCurrentMembershipDetails({ currentMembershipDetails }));
				}

				if (data) {
					notification.success({
						message: "Subscription successful",
					});
					if (afterSubscribe) {
						afterSubscribe();
					} else {
						navigate(-1);
					}
				}
				if (error) {
					console.log("error", error);
					notification.error({
						message: error.data?.error || "An error occured during subscription",
					});
				}
			}
		} catch (error) {
			console.log("error", error);
			notification.error({
				message: error.data?.error || "An error occured during subscription",
			});
		}
	};

	return (
		<StyledStripeForm>
			{subscribingToGym && (
				<>
					<Paragraph2 style={{ fontSize: "28px", paddingBottom: "0px", marginBottom: "10px" }}>
						Membership Info
					</Paragraph2>
					<Paragraph2 style={{ fontSize: "14px", color: "#4f5661" }}>{domainGymResponse?.data?.description}</Paragraph2>
					<div
						style={{
							border: "1px solid #E3E5E7",
							borderRadius: "8px",
							justifyContent: "center",
							alignItems: "center",
							textAlign: "center",
							borderStyle: "solid",
							marginBottom: "10px",
							paddingTop: "20px",
						}}
					>
						<h1 style={{ fontWeight: 700, fontSize: "40px" }}>
							${(subscribingToGym.price / 100).toFixed(2)} <span style={{ fontSize: "16px" }}>/month</span>
						</h1>
					</div>
				</>
			)}
			<form onSubmit={handleSubmit}>
				{message && (
					<div>
						<h4 style={{ color: "red" }}>
							<InfoCircleOutlined style={{ paddingRight: "8px" }} />
							{message}
						</h4>
					</div>
				)}
				<PaymentElement />
				<Button type="submit" uppercase>
					<Paragraph2>{buttonText ? buttonText : "Submit"}</Paragraph2>
				</Button>
			</form>
			{isLoading && <LoadingMask />}
		</StyledStripeForm>
	);
};

export default StripeForm;

