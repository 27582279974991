import React, { useCallback, useEffect } from "react";
import { FormDateSelect } from "components/Common/Form/FormDateSelect";
import { FormTimezoneSelect } from "components/Common/Form/FormTimezoneSelect";
import { FormSelect } from "components/Common/Form/FormSelect";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { FieldTimeOutlined } from "@ant-design/icons";
dayjs.extend(utc);
dayjs.extend(timezone);

const DateTimeTimezone = ({ form, defaultTimezone, currentDatetime, setCurrentDatetime, disabled, edit }) => {
	const tz = form.getFieldValue("workoutTimezone") || defaultTimezone;
	const [timezone, setTimezone] = React.useState(tz);
	const date = (form.getFieldValue("workoutDate") || dayjs().tz(timezone).startOf("day")).format("YYYY-MM-DD");
	const workoutTime = form.getFieldValue("workoutTime");
	const [time, setTime] = React.useState(workoutTime);

	const options = Array.from({ length: 24 }, (_, hour) =>
		Array.from({ length: 4 }, (_, quarter) => {
			const minute = quarter * 15;
			const value = `${String(hour).padStart(2, "0")}:${String(minute).padStart(2, "0")}`;
			const currentTimeInTimeZone = dayjs().tz(timezone).format("YYYY-MM-DD hh:mm A");
			const label = dayjs(`2023-01-01 ${value}`).format("h:mm A");
			const disabled = dayjs(`${date} ${value}`).tz(timezone).isBefore(currentTimeInTimeZone);
			return { value, label, id: value, disabled };
		})
	).flat();

	const [timeOptions, setTimeOptions] = React.useState([]);

	const sortedOptions = [...options, ...timeOptions].sort((a, b) =>
		dayjs(`${date} ${a.value}`).isBefore(dayjs(`${date} ${b.value}`)) ? -1 : 1
	);

	const addOption = useCallback(
		function addOption(newValue) {
			const existingValue = sortedOptions.find((o) => o.value === newValue.value);
			if (!existingValue) {
				setTimeOptions([newValue]);
			}
		},
		[sortedOptions]
	);

	useEffect(() => {
		if (workoutTime) {
			const currentTimeInTimeZone = dayjs().tz(timezone).format("YYYY-MM-DD hh:mm A");
			const disabled = dayjs(`${date} ${workoutTime}`)
				.tz(timezone)
				.subtract(edit ? 2 : 0, "hours")
				.isBefore(currentTimeInTimeZone);
			const newValue = {
				value: workoutTime,
				label: dayjs(`2023-01-01 ${workoutTime}`).format("h:mm A"),
				id: workoutTime,
				disabled,
			};
			addOption(newValue);
		}
	}, [workoutTime, timezone, date, addOption, edit]);

	useEffect(() => {
		// Antd does not display the placeholder when showSearch is true; this is a workaround
		const selectInput = document.querySelector(`input#workoutTime`);
		if (selectInput) {
			selectInput.placeholder = !workoutTime && !time ? "Select Time" : "";
		}
	}, [workoutTime, form, time]);

	return (
		<>
			<FormDateSelect
				name="workoutDate"
				label={<span style={{ whiteSpace: "nowrap" }}>Date</span>}
				required={false}
				rules={[
					() => ({
						validator(_, value) {
							if (!value) {
								return Promise.reject(new Error("This field is required"));
							}
							const timezone = form.getFieldValue("workoutTimezone") || defaultTimezone;
							const now = dayjs().tz(timezone).startOf("day");
							if (value.isBefore(now, "day")) {
								return Promise.reject(new Error("The selected date cannot be in the past."));
							}
							return Promise.resolve();
						},
					}),
				]}
				disabled={disabled}
				disabledDate={(currentDate) => {
					const timezone = form.getFieldValue("workoutTimezone") || defaultTimezone;
					dayjs.tz.setDefault(timezone);
					const now = dayjs().tz(timezone).startOf("day");
					return currentDate && currentDate.isBefore(now, "day");
				}}
				onOpenChange={(open) => {
					if (!open) {
						const workoutDate = form.getFieldValue("workoutDate");
						const workoutTime = form.getFieldValue("workoutTime");
						if (dayjs(workoutDate).isValid()) {
							setCurrentDatetime(dayjs(workoutDate).format("YYYY-MM-DD"));
						}

						if (workoutTime) {
							form.validateFields(["workoutTime"]);
						}
					}
				}}
			/>
			<FormTimezoneSelect
				name="workoutTimezone"
				rules={[{ required: true, message: "This field is required" }]}
				label="Timezone"
				currentDatetime={currentDatetime}
				disabled={disabled}
				onChange={(newTimezone) => {
					const workoutDate = form.getFieldValue("workoutDate");
					const workoutTime = form.getFieldValue("workoutTime");
					setTimezone(newTimezone);
					if (workoutDate) {
						form.validateFields(["workoutDate"]);
					}
					if (workoutTime) {
						form.validateFields(["workoutTime"]);
					}
				}}
			/>
			<FormSelect
				suffixIcon={<FieldTimeOutlined style={{ fontSize: 20 }} />}
				name="workoutTime"
				options={sortedOptions}
				label="Time"
				form={form}
				placeholder="Select Time"
				size="large"
				style={{ height: 48 }}
				showSearch
				disabled={disabled}
				rules={[
					{ required: true, message: "This field is required" },
					() => ({
						validator(_, value) {
							const currentTimeInTimeZone = dayjs()
								.tz(timezone)
								.subtract(edit ? 2 : 0, "hours")
								.format("YYYY-MM-DD hh:mm A");
							const disabled = dayjs(`${date} ${value}`).tz(timezone).isBefore(currentTimeInTimeZone);
							if (disabled) {
								return Promise.reject(
									new Error(
										edit
											? "The selected time cannot be more than 2 hours in the past."
											: "The selected time cannot be in the past."
									)
								);
							}
							return Promise.resolve();
						},
					}),
				]}
				filterOption={(input, option) => {
					return (
						option?.label?.toLowerCase()?.includes(input?.toLowerCase()) ||
						option?.value?.toLowerCase()?.includes(input?.toLowerCase())
					);
				}}
				onChange={(value) => {
					setTime(value);
				}}
				onSearch={(e) => {
					const value = e?.replace(/\s+/g, "")?.toUpperCase();
					const rawTime = /^(0?[1-9]|1[0-2]):[0-5][0-9]\s?$/i;
					const timePattern = /^(0?[1-9]|1[0-2]):[0-5][0-9]\s?(AM|PM)$/i;
					function getNewValue(validTime) {
						const currentTimeInTimeZone = dayjs().tz(timezone).format("YYYY-MM-DD hh:mm A");
						const disabled = dayjs(`${date} ${value}`).tz(timezone).isBefore(currentTimeInTimeZone);
						return {
							value: validTime.format("HH:mm"),
							label: dayjs(`2023-01-01 ${validTime.format("HH:mm")}`).format("h:mm A"),
							id: validTime.format("HH:mm"),
							disabled,
						};
					}
					if (rawTime.test(value)) {
						const validTime = dayjs(`${date} ${value}AM`, "YYYY-MM-DD hh:mmA");
						const newValue = getNewValue(validTime);
						addOption(newValue);
						form.setFieldsValue({ workoutTime: newValue.value });
						form.validateFields(["workoutTime"]);
					}
					if (timePattern.test(value)) {
						const validTime = dayjs(`${date} ${value}`, "YYYY-MM-DD hh:mmA");
						const newValue = getNewValue(validTime);
						addOption(newValue);
						form.setFieldsValue({ workoutTime: newValue.value });
						form.validateFields(["workoutTime"]);
					}
				}}
			/>
		</>
	);
};

export default DateTimeTimezone;

