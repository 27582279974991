// -----------------------------------------------------------------------------
// Libraries
// -----------------------------------------------------------------------------
import React from "react";
import cx from "classnames";
import { Modal } from "antd";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Elements } from "@stripe/react-stripe-js";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import { Heading2 } from "themes/default/_typography";
import { StripeForm } from "components/Common/Form/StripeForm";
import { useParams } from "react-router";
import { useGetDomainGymQuery } from "store/api/api";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const AddPaymentMethodModal = ({
	open,
	setOpen,
	paymentIntent,
	className,
	clientSecret,
	setupIntentId,
	subscribingToGym,
	currentCustomerId,
	afterSubscribe,
	update,
	closable,
	onClose,
}) => {
	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);

	return (
		<Modal
			className={cx(className, "sb-add-payment-method-modal")}
			title={
				subscribingToGym ? (
					<Heading2>Subscribe to {domainGymResponse?.data?.name}</Heading2>
				) : (
					<Heading2>Add Payment Method</Heading2>
				)
			}
			centered
			open={open}
			onOk={() => setOpen(false)}
			onCancel={onClose || (() => setOpen(false))}
			width={656}
			footer={null}
			maskClosable={false}
			closable={closable}
		>
			<Elements
				stripe={stripePromise}
				options={{
					clientSecret: paymentIntent ? paymentIntent.client_secret : clientSecret,
					appearance: {
						theme: "night",
						labels: "floating",
					},
				}}
			>
				<StripeForm
					origin="creator"
					buttonText="Close"
					setupIntentId={setupIntentId}
					clientSecret={clientSecret}
					subscribingToGym={subscribingToGym}
					currentCustomerId={currentCustomerId}
					afterSubscribe={afterSubscribe}
					update={update}
				/>
			</Elements>
		</Modal>
	);
};

export default AddPaymentMethodModal;

