// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import Table from "antd/lib/table";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import moment from "moment";
import { Button, Input, Space, Switch, Tooltip, Modal } from "antd";
import { CaretDownFilled, SearchOutlined } from "@ant-design/icons";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { secondsToTime } from "helpers/generic";
import Categories from "components/UI/Categories";
import VideoPreview from "components/UI/Video/VideoPreview";
import VideoPlayerModal from "components/UI/VideoPlayer/VideoPlayerModal";
import { LiveStreamIcon, ScheduledLiveStreamIcon, ScheduledOnDemandIcon, OnDemandIcon } from "components/Icons";
import Dropdown from "components/Dropdown";

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
import { selectMembersWorkoutsSelectedCategories } from "store/selectors";
import { updateMembersWorkoutsSelectedCategories, updateSelectedWorkout } from "store/slices";
import {
	useEditWorkoutMutation,
	useGetCategoriesQuery,
	useGetDomainGymQuery,
	useGetGenresQuery,
	useGetGymWorkoutsQuery,
	useGetScheduledStreamsQuery,
} from "store/api/api";

// -----------------------------------------------------------------------------
// Style, utils and assets
// -----------------------------------------------------------------------------
import PencilSVG from "images/pencil.svg";
import { defaultTimezone, workoutTypes, workoutStatuses } from "helpers/constants";
import "./styles.scss";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const WorkoutActiveToggle = ({ workout, scheduledStreams, pendingEdits, handleChangeActive }) => {
	const stream = scheduledStreams?.find((stream) => workout.id === stream.workout_id);
	const timezone = workout?.time_zone || stream?.time_zone || defaultTimezone;
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGymId = domainGymResponse.data?.id;
	const getWorkoutsResponse = useGetGymWorkoutsQuery({ gym_id: domainGymId }, { skip: !domainGymResponse.data });

	const isScheduledOnDemand = workout?.type === workoutTypes.SCHEDULED_ON_DEMAND;
	const isScheduledLiveStream = workout?.type === workoutTypes.SCHEDULED_LIVE_STREAM;
	const isCurrentlyLive = workout?.type === workoutTypes.CURRENTLY_LIVE;

	const isDisabled =
		workout.duration === 0 ||
		pendingEdits.includes(workout.id) ||
		isCurrentlyLive ||
		isScheduledLiveStream ||
		isScheduledOnDemand;

	useEffect(() => {
		if (workout.start_time) {
			const timeDifferenceMs = moment
				.tz(workout.start_time, "YYYY-MM-DDTHH:mm:ss", timezone)
				.diff(moment().tz(timezone));
			const timeout = setTimeout(() => {
				getWorkoutsResponse.refetch();
			}, timeDifferenceMs + 1);
			return () => clearTimeout(timeout);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isScheduledOnDemand, timezone, workout.start_time]);

	return (
		<Switch
			loading={pendingEdits.includes(workout.id)}
			disabled={isDisabled}
			checked={workout.status === workoutStatuses.ACTIVE}
			onClick={(checked, event) => handleChangeActive(checked, event, workout)}
		/>
	);
};

const SearchFilters = ({
	handleChangeSearchText,
	categoriesMenu,
	selectedCategories,
	trainersMenu,
	equipmentMenu,
	trainerFilters,
	equipmentFilters,
	genreMenu,
	selectedGenres,
	durationMenu,
	selectedDurations,
}) => {
	return (
		<div
			className="sb-workouts-table__search-filters"
			data-id="sb-workouts-table-search-filters"
			style={{ gap: "8px", display: "flex", padding: "8px", flexWrap: "wrap" }}
		>
			<Space.Compact size="large" data-id="sb-workouts-table-search">
				<Input
					placeholder="Search Workouts"
					prefix={<SearchOutlined style={{ color: "grey", paddingRight: "8px" }} />}
					onChange={handleChangeSearchText}
					style={{ borderRadius: "100px", minWidth: "200px" }}
				/>
			</Space.Compact>
			<Space.Compact style={{ flex: 1 }} size="large" data-id="sb-workouts-table-categories-filter">
				<Dropdown trigger={["click"]} menu={categoriesMenu} overlayClassName="dropdown-no-close">
					<Button
						style={{
							borderColor: " #E3E5E7",
							textTransform: "none",
							color: "#4F5661",
							fontWeight: "700",
							fontSize: "14px",
							lineHeight: "24px",
							fontFamily: "DM Sans",
							width: "100%",
							flex: 1,
						}}
					>
						Workout Category
						{selectedCategories.length > 0 && selectedCategories[0] !== 0 && `(${selectedCategories.length})`}
						<CaretDownFilled
							style={{
								color: "#4F5661",
								fontSize: "12px",
								lineHeight: "0px",
							}}
						/>
					</Button>
				</Dropdown>
			</Space.Compact>
			<Space.Compact size="large" data-id="sb-workouts-table-trainers-filter">
				<Dropdown trigger={["click"]} menu={trainersMenu} overlayClassName="dropdown-no-close">
					<Button
						style={{
							borderColor: " #E3E5E7",
							textTransform: "none",
							color: "#4F5661",
							fontWeight: "700",
							fontSize: "14px",
							lineHeight: "24px",
							fontFamily: "DM Sans",
							width: "100%",
						}}
					>
						Trainers {trainerFilters.length > 0 && `(${trainerFilters.length})`}
						<CaretDownFilled
							style={{
								color: "#4F5661",
								fontSize: "12px",
								lineHeight: "0px",
							}}
						/>
					</Button>
				</Dropdown>
			</Space.Compact>
			<Space.Compact size="large" data-id="sb-workouts-table-equipment-filter">
				<Dropdown trigger={["click"]} menu={equipmentMenu} overlayClassName="dropdown-no-close">
					<Button
						style={{
							borderColor: " #E3E5E7",
							textTransform: "none",
							color: "#4F5661",
							fontWeight: "700",
							fontSize: "14px",
							lineHeight: "24px",
							fontFamily: "DM Sans",
							width: "100%",
						}}
					>
						Equipment {equipmentFilters.length > 0 && `(${equipmentFilters.length})`}
						<CaretDownFilled
							style={{
								color: "#4F5661",
								fontSize: "12px",
								lineHeight: "0px",
							}}
						/>
					</Button>
				</Dropdown>
			</Space.Compact>
			<Space.Compact size="large" data-id="sb-workouts-table-duration-filter">
				<Dropdown trigger={["click"]} menu={durationMenu} overlayClassName="dropdown-no-close">
					<Button
						style={{
							borderColor: " #E3E5E7",
							textTransform: "none",
							color: "#4F5661",
							fontWeight: "700",
							fontSize: "14px",
							lineHeight: "24px",
							fontFamily: "DM Sans",
							width: "100%",
						}}
					>
						Duration {selectedDurations.length > 0 && `(${selectedDurations.length})`}
						<CaretDownFilled
							style={{
								color: "#4F5661",
								fontSize: "12px",
								lineHeight: "0px",
							}}
						/>
					</Button>
				</Dropdown>
			</Space.Compact>
			<Space.Compact size="large" data-id="sb-workouts-table-genre-filter">
				<Dropdown trigger={["click"]} menu={genreMenu} overlayClassName="dropdown-no-close">
					<Button
						style={{
							borderColor: " #E3E5E7",
							textTransform: "none",
							color: "#4F5661",
							fontWeight: "700",
							fontSize: "14px",
							lineHeight: "24px",
							fontFamily: "DM Sans",
							width: "100%",
						}}
					>
						Music {selectedGenres.length > 0 && `(${selectedGenres.length})`}
						<CaretDownFilled
							style={{
								color: "#4F5661",
								fontSize: "12px",
								lineHeight: "0px",
							}}
						/>
					</Button>
				</Dropdown>
			</Space.Compact>
		</div>
	);
};
const WorkoutsTable = ({ setShowEditWorkoutModal, setSelectedLiveStream, setIsEditingLive }) => {
	const dispatch = useDispatch();
	let { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGymId = domainGymResponse?.data?.id;
	const { data: domainGym } = domainGymResponse;
	const primaryColor = domainGym.brand_color1 || "#ff865c";

	const workoutsResponse = useGetGymWorkoutsQuery({ gym_id: domainGymId }, { skip: !domainGymResponse.data });
	const [workouts, setWorkouts] = useState([]);
	const categoriesResponse = useGetCategoriesQuery(domainGymId, { skip: !domainGymResponse.data });
	const categories = categoriesResponse.data?.categories || [];
	const genresResponse = useGetGenresQuery();
	const genres = genresResponse.data?.genres || [];
	const scheduledLiveStreamWorkouts =
		workoutsResponse.data?.workouts?.filter((workout) => workout.type === workoutTypes.SCHEDULED_LIVE_STREAM) || [];
	const { data: scheduledStreams } = useGetScheduledStreamsQuery(domainGymId, { skip: !domainGymResponse.data });
	const selectedCategories = useSelector(selectMembersWorkoutsSelectedCategories);

	const [editWorkoutTrigger] = useEditWorkoutMutation();
	const [isShowingVideoPlayer, updateIsShowingVideoPlayer] = useState(false);
	const [fileSelected, updateFileSelected] = useState(null);
	const [searchText, setSearchText] = useState("");
	const categoriesFilters = useSelector(selectMembersWorkoutsSelectedCategories);
	const [equipmentFilters, setEquipmentFilters] = useState([]);
	const [trainerFilters, setTrainerFilters] = useState([]);
	const setWorkoutSelected = useCallback(
		(workout) => {
			dispatch(updateSelectedWorkout({ workout }));
			setShowEditWorkoutModal(true);
		},
		[dispatch, setShowEditWorkoutModal]
	);
	const [selectedDurations, setSelectedDurations] = useState([]);
	const [selectedGenres, setSelectedGenres] = useState([]);
	const [pendingEdits, setPendingEdits] = useState([]);
	const [toggleDisabled, setToggleDisabled] = useState(false);

	const handleChangeSearchText = (e) => {
		setSearchText(e?.target?.value);
	};

	useEffect(() => {
		if (workoutsResponse.data) {
			setWorkouts(workoutsResponse.data?.workouts || []);
		}
	}, [workoutsResponse]);

	useEffect(() => {
		return () => {
			dispatch(updateMembersWorkoutsSelectedCategories([0]));
		};
	}, [dispatch]);

	const handleCategoryClick = (category) => {
		const category_id = parseInt(category.key);

		if (!selectedCategories.includes(category_id)) {
			if (category_id === 0) {
				dispatch(updateMembersWorkoutsSelectedCategories([0]));
			} else {
				if (selectedCategories.includes(0)) {
					dispatch(updateMembersWorkoutsSelectedCategories([category_id]));
				} else {
					dispatch(updateMembersWorkoutsSelectedCategories([...selectedCategories, category_id]));
				}
			}
		} else if (category_id !== 0) {
			const newCategories = [...selectedCategories];

			newCategories.splice(
				selectedCategories.findIndex((c) => c === category_id),
				1
			);

			dispatch(updateMembersWorkoutsSelectedCategories(newCategories.length > 0 ? newCategories : [0]));
		}
	};

	const handleEditWorkoutActive = async (workout, active) => {
		try {
			await editWorkoutTrigger({
				workout_id: workout.id,
				gym_id: workout.gym_id,
				status: active ? workoutStatuses.ACTIVE : workoutStatuses.INACTIVE,
			}).unwrap();
		} catch (error) {
			console.error("handleEditWorkoutActive error: ", error);
		}
	};

	const handleChangeActive = async (checked, event, record) => {
		setPendingEdits([...pendingEdits, record.id]);
		await handleEditWorkoutActive(record, checked);
		setPendingEdits([...pendingEdits.filter((id) => id !== record.id)]);
		workoutsResponse.refetch();
	};

	//Why is this showing double
	const categoryItems = categories
		.map((category) => {
			return {
				label: <div className="dropdown-list-item">{category.name?.trim?.() || "Empty Category"}</div>,
				key: category.id,
				danger: categoriesFilters.includes(category.id) ? true : false,
				item: category.name,
				title: category.name,
			};
		})
		.sort((optionA, optionB) => (optionA?.item ?? "").toLowerCase().localeCompare((optionB?.item ?? "").toLowerCase()));

	const categoriesMenu = {
		items: [
			{
				label: "All Categories",
				key: 0,
				danger: categoriesFilters.includes(0) ? true : false,
				title: "All Categories",
			},
			...categoryItems,
		],
		onClick: handleCategoryClick,
	};
	const allTrainers = workouts.map((workout) => workout.coach);
	const uniqueTrainers = [...new Set(allTrainers.flat())];

	const trainerItems = uniqueTrainers
		.map((item, index) => {
			return {
				label: <div className="dropdown-list-item">{item?.trim?.() || "No Trainer"}</div>,
				key: index,
				danger: trainerFilters.includes(item) ? true : false,
				item,
				onClick: () => handleTrainerClick(item?.trim?.()),
				title: item,
			};
		})
		.sort((optionA, optionB) => (optionA?.item ?? "").toLowerCase().localeCompare((optionB?.item ?? "").toLowerCase()));

	const handleTrainerClick = (clickedTrainer) => {
		if (trainerFilters.includes(clickedTrainer)) {
			const newTrainers = trainerFilters.filter((g) => g !== clickedTrainer);
			setTrainerFilters(newTrainers);
		} else {
			setTrainerFilters([...trainerFilters, clickedTrainer]);
		}
	};

	const trainersMenu = {
		items: trainerItems,
	};

	const allEquipment = workouts.map((workout) => workout.equipment.split(",").map((e) => e?.trim()));
	const uniqueEquipment = [...new Set(allEquipment.flat())];

	const handleEquipmentClick = (clickedEquipment) => {
		if (equipmentFilters.includes(clickedEquipment)) {
			const newEquipment = equipmentFilters.filter((g) => g !== clickedEquipment);
			setEquipmentFilters(newEquipment);
		} else {
			setEquipmentFilters([...equipmentFilters, clickedEquipment]);
		}
	};
	const equipmentItems = uniqueEquipment
		.map((item, index) => {
			return {
				label: <div className="dropdown-list-item">{item?.trim?.() || "No Equipment"}</div>,
				value: item,
				key: index,
				danger: equipmentFilters.includes(item) ? true : false,
				item,
				onClick: () => handleEquipmentClick(item),
			};
		})
		.sort((optionA, optionB) => (optionA?.item ?? "").toLowerCase().localeCompare((optionB?.item ?? "").toLowerCase()));

	const equipmentMenu = {
		items: equipmentItems,
	};

	const handleGenreClick = (clicked) => {
		const clickedGenre = genres[clicked.key];
		const isAlreadySelected = selectedGenres.map((g) => g.title).includes(clickedGenre.title);
		if (isAlreadySelected) {
			const newGenres = selectedGenres.filter((g) => g.title !== clickedGenre.title);
			setSelectedGenres(newGenres);
		} else {
			setSelectedGenres([...selectedGenres, clickedGenre]);
		}
	};

	const genreItems = genres.map((item, index) => {
		return {
			label: <div className="dropdown-list-item">{item.title?.trim?.() || "No Genre"}</div>,
			key: index,
			danger: selectedGenres.map((g) => g.title).includes(genres[index].title) ? true : false,
			item: item.title,
		};
	});

	const genreMenu = {
		items: genreItems.sort((optionA, optionB) =>
			(optionA?.item ?? "").toLowerCase().localeCompare((optionB?.item ?? "").toLowerCase())
		),
		onClick: handleGenreClick,
	};

	const durations = [
		{ min: 0, max: 15, label: "0-15 min" },
		{ min: 15, max: 30, label: "15-30 min" },
		{ min: 30, max: 45, label: "30-45 min" },
		{ min: 45, max: 60, label: "45-60 min" },
		{ min: 60, max: 90, label: "60-90 min" },
		{ min: 90, max: 120, label: "90-120 min" },
		{ min: 120, max: 1000, label: "120+ min" },
	];

	const handleDurationClick = (duration) => {
		const clicked = durations[duration.key];
		const selectedLabels = selectedDurations.map((d) => d.label);
		const isAlreadySelected = selectedLabels.includes(clicked.label);
		if (isAlreadySelected) {
			const newDurations = selectedDurations.filter((d) => d.label !== clicked.label);
			setSelectedDurations(newDurations);
		} else {
			setSelectedDurations([...selectedDurations, clicked]);
		}
	};

	const [confirmOpen, setConfirmOpen] = useState(false);

	const isWithinDuration = (workout) => {
		const duration = workout.duration / 60;
		const selectedDurationsLabels = new Set(selectedDurations.map((d) => d.label));

		if (selectedDurationsLabels.size === 0) return true;

		return durations.some(
			({ label, min, max }) => selectedDurationsLabels.has(label) && duration >= min && duration <= max
		);
	};

	const isNotScheduledLiveStreamWorkout = (workout) => {
		const stream = scheduledStreams?.find((stream) => stream.workout_id === workout.id);
		const scheduledLiveStreamWorkout = scheduledLiveStreamWorkouts?.find((w) => w.id === workout.id);

		return (!scheduledLiveStreamWorkout && !stream) || workout.is_previously_live;
	};

	const workoutsToActivate = workouts
		.filter((workout) => isNotScheduledLiveStreamWorkout(workout))
		.filter((workout) => isWithinDuration(workout))
		.filter(
			(workout) =>
				categoriesFilters.includes(0) || workout.categories.some((c) => categoriesFilters.includes(c.category_id))
		)
		.filter((workout) => (trainerFilters.length > 0 ? trainerFilters.includes(workout.coach) : true))
		.filter((workout) =>
			equipmentFilters.length > 0
				? workout.equipment.split(",").some((e) => equipmentFilters.includes(e?.trim()))
				: true
		)
		.filter(
			(workout) => selectedGenres.map((g) => g.title).includes(workout.music_genre) || selectedGenres.length === 0
		)
		.filter((workout) => workout.duration !== 0)
		.filter(
			(workout) =>
				workout.name?.toLowerCase().includes(searchText.toLowerCase()) ||
				workout.description?.toLowerCase().includes(searchText.toLowerCase())
		);

	const handleToggleAllActive = async (checked) => {
		setConfirmOpen(false);
		setToggleDisabled(true);
		const filteredWorkouts = workoutsToActivate
			.filter((workout) => {
				const isDraft = [workoutStatuses.DRAFT, workoutStatuses.INACTIVE].includes(workout.status);
				return !!isDraft === !!checked;
			})
			.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));

		setPendingEdits(filteredWorkouts.map((workout) => workout.id));
		await Promise.all(filteredWorkouts.map((workout) => handleEditWorkoutActive(workout, checked)));
		setToggleDisabled(false);
		setActiveState(checked);
		setPendingEdits([]);
		workoutsResponse.refetch();
	};

	const draftWorkouts = workoutsToActivate.filter((workout) =>
		[workoutStatuses.DRAFT, workoutStatuses.INACTIVE].includes(workout.status)
	);

	const [activeState, setActiveState] = useState(!draftWorkouts.length);

	useEffect(() => {
		setActiveState(!draftWorkouts.length);
	}, [draftWorkouts]);

	const confirmToggle = () => {
		setConfirmOpen(true);
	};

	const durationItems = durations.map((item, index) => {
		return {
			label: item.label,
			key: index,
			danger: selectedDurations.map((d) => d.label).includes(durations[index].label) ? true : false,
		};
	});

	const durationMenu = {
		items: durationItems,
		onClick: handleDurationClick,
	};

	const getFormattedStartTime = (startTime, timezone) => {
		return moment.tz(startTime, "YYYY-MM-DDTHH:mm:ss", timezone).format("MM/DD h:mm A z");
	};

	const getWorkoutTypeComponent = (workout) => {
		const stream = scheduledStreams?.find((stream) => workout.id === stream.workout_id);
		const timezone = workout?.time_zone || stream?.time_zone || defaultTimezone;
		const isScheduledOnDemand = workout?.type === workoutTypes.SCHEDULED_ON_DEMAND;
		const isScheduledLiveStream = workout?.type === workoutTypes.SCHEDULED_LIVE_STREAM;
		const isCurrentlyLive = workout?.type === workoutTypes.CURRENTLY_LIVE;
		const isOnDemand = workout?.type === workoutTypes.ON_DEMAND;

		const getWorkoutTypeItem = (title, IconComponent, iconProps, className) => (
			<Tooltip title={title}>
				<div className={`workout-type-box ${className}`}>
					<IconComponent {...iconProps} fill={primaryColor} />
				</div>
			</Tooltip>
		);

		if (isCurrentlyLive) {
			return getWorkoutTypeItem(
				"Livestreaming",
				LiveStreamIcon,
				{ fill: "white", width: 20, height: 19 },
				"workout-type-box__live"
			);
		}

		if (isScheduledLiveStream) {
			const startTimeFormatted = getFormattedStartTime(workout?.start_time, timezone);
			return getWorkoutTypeItem(
				`Livestreaming at ${startTimeFormatted}`,
				ScheduledLiveStreamIcon,
				{ fill: primaryColor, width: 20, height: 19 },
				"workout-type-box__on-demand"
			);
		}

		if (isScheduledOnDemand) {
			const startTimeFormatted = getFormattedStartTime(workout?.start_time, timezone);
			return getWorkoutTypeItem(
				`The workout will activate at ${startTimeFormatted}`,
				ScheduledOnDemandIcon,
				{ fill: primaryColor, width: 20, height: 19 },
				"workout-type-box__on-demand"
			);
		}

		if (isOnDemand) {
			return getWorkoutTypeItem(
				"On demand workout",
				OnDemandIcon,
				{ fill: primaryColor, width: 12, height: 12 },
				"workout-type-box__on-demand"
			);
		}

		// Default On Demand
		return getWorkoutTypeItem(
			"On demand workout",
			OnDemandIcon,
			{ fill: primaryColor, width: 12, height: 12 },
			"workout-type-box__on-demand"
		);
	};

	const columns = [
		{
			title: "Video",
			dataIndex: "video",
			key: "video",
			render: (_, record) => {
				return (
					<div className="video-column-container" data-id="clVideo">
						<div className="workout-type" data-id="workoutTypeIcon">
							{getWorkoutTypeComponent(record)}
						</div>
						<VideoPreview
							updateIsShowingVideoPlayer={updateIsShowingVideoPlayer}
							updateFileSelected={updateFileSelected}
							record={record}
						/>
					</div>
				);
			},
		},
		{
			title: "Music",
			dataIndex: "music",
			key: "music",
			render: (_, record) => {
				return <span>{record.music_genre}</span>;
			},
		},
		{
			title: "Categories",
			dataIndex: "categories",
			key: "categories",
			className: "categories-column",
			render: (row) => (row ? <Categories values={row.map((r) => r.name)} /> : null),
		},
		{
			title: "Duration",
			dataIndex: "duration",
			key: "duration",
			render: (_, record) => {
				return record.type === workoutTypes.CURRENTLY_LIVE ? (
					<span>Processing...</span>
				) : (
					<span>{secondsToTime(record.duration)}</span>
				);
			},
		},
		{
			title: "Active ",
			dataIndex: "active",
			key: "active",
			align: "left",
			filtered: false,
			filterDropdown: <></>,
			filterDropdownOpen: false,
			filterIcon: (
				<Tooltip title="Apply filters before batch activating" trigger="hover">
					<Switch
						disabled={toggleDisabled}
						loading={toggleDisabled}
						checked={activeState}
						onChange={(checked) => confirmToggle(checked)}
					/>
				</Tooltip>
			),
			render: (_, record) => (
				<WorkoutActiveToggle
					workout={record}
					pendingEdits={pendingEdits}
					handleChangeActive={handleChangeActive}
					scheduledStreams={scheduledStreams}
				/>
			),
		},
		{
			title: "Actions",
			dataIndex: "actions",
			key: "actions",
			fixed: "right",
			width: 100,
			render: (_, record) => {
				return (
					<div
						className="sb-workouts-table__edit tag"
						data-id={`edit-workout-button`}
						onClick={() => {
							const stream = scheduledStreams?.find((stream) => record.id === stream.workout_id);
							const scheduledLiveStreamWorkout = scheduledLiveStreamWorkouts?.find((w) => w.id === record.id);

							setWorkoutSelected(scheduledLiveStreamWorkout ?? record);
							setSelectedLiveStream(stream);
							setIsEditingLive(!!scheduledLiveStreamWorkout);
						}}
					>
						<img src={PencilSVG} alt="edit" /> <span style={{ marginLeft: 10 }}>Edit</span>
					</div>
				);
			},
		},
	];

	const dataSource = workouts
		.filter((workout) => isWithinDuration(workout))
		.filter(
			(workout) =>
				categoriesFilters.includes(0) || workout.categories.some((c) => categoriesFilters.includes(c.category_id))
		)
		.filter((workout) => (trainerFilters.length > 0 ? trainerFilters.includes(workout.coach) : true))
		.filter((workout) =>
			equipmentFilters.length > 0
				? workout.equipment.split(",").some((e) => equipmentFilters.includes(e?.trim()))
				: true
		)
		.filter(
			(workout) => selectedGenres.map((g) => g.title).includes(workout.music_genre) || selectedGenres.length === 0
		)
		.filter(
			(workout) =>
				workout.name?.toLowerCase().includes(searchText.toLowerCase()) ||
				workout.description?.toLowerCase().includes(searchText.toLowerCase())
		)
		.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));

	return (
		<div className="sb-workouts-table">
			<SearchFilters
				handleChangeSearchText={handleChangeSearchText}
				categoriesMenu={categoriesMenu}
				selectedCategories={selectedCategories}
				trainersMenu={trainersMenu}
				equipmentMenu={equipmentMenu}
				trainerFilters={trainerFilters}
				equipmentFilters={equipmentFilters}
				genreMenu={genreMenu}
				selectedGenres={selectedGenres}
				durationMenu={durationMenu}
				selectedDurations={selectedDurations}
			/>
			<Modal
				title="Confirm"
				open={confirmOpen}
				onOk={() => handleToggleAllActive(!activeState)}
				onCancel={() => setConfirmOpen(false)}
				okText="Confirm"
				cancelText="Cancel"
				maskClosable={false}
				footer={
					<div className="sb-confirm-form__actions">
						<Button className="confirm-btn" onClick={() => setConfirmOpen(false)}>
							Go Back
						</Button>
						<Button className="confirm-btn" onClick={() => handleToggleAllActive(!activeState)} type="primary">
							Confirm
						</Button>
					</div>
				}
			>
				Are you sure you want to {activeState ? "In-Activate" : "Activate"} all of these{" "}
				<strong>
					{
						workoutsToActivate.filter((item) => {
							const isDraft = [workoutStatuses.DRAFT, workoutStatuses.INACTIVE].includes(item.status);
							return !!isDraft === !activeState;
						}).length
					}{" "}
				</strong>
				workouts?
			</Modal>
			{workouts && (
				<Table
					scroll={{ x: dataSource?.length ? "max-content" : undefined }}
					data-id="sb-workouts-content-table"
					columns={dataSource?.length ? columns : []}
					dataSource={dataSource}
					rowKey="id"
					onRow={(record, index) => ({
						style: {
							background: record.type === workoutTypes.CURRENTLY_LIVE ? "rgba(0,0,0,.1)" : "default",
							cursor: record.type === workoutTypes.CURRENTLY_LIVE ? "null" : "pointer",
						},
					})}
				/>
			)}
			<VideoPlayerModal
				file={fileSelected}
				isShowing={isShowingVideoPlayer}
				updateIsShowingVideoPlayer={updateIsShowingVideoPlayer}
			/>
		</div>
	);
};

export default WorkoutsTable;
