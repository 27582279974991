// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useCallback, useState } from "react";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { Button } from "components/Common/Button";
import { Paragraph3, Heading3 } from "themes/default/_typography";
import { KeyIcon, DumbellIcon, StarIcon, PersonPlusIcon } from "components/Icons";
import { AccountCard } from "components/Common/Cards/AccountCard";
import { DeleteUserModal } from "components/Modals/DeleteUserModal";
import ProfileBanner from "components/UI/ProfileBanner";
import { Modal, Typography } from "antd";
import UpdatePassword from "pages/Dashboard/Header/UpdatePassword";
import EditProfile from "components/EditProfile";
import usePrimaryColor from "helpers/hooks/usePrimaryColor";

// -----------------------------------------------------------------------------
// Styles and helpers
// -----------------------------------------------------------------------------
import useLogout from "utils/useLogout";
import StyledProfile from "./styles";
import { useNavigate, useParams } from "react-router";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const Profile = () => {
	const { gym } = useParams();
	const navigate = useNavigate();
	const primaryColor = usePrimaryColor();
	const [openResetPassword, setOpenResetPassword] = useState(false);
	const [openEditProfile, setOpenEditProfile] = useState(false);
	const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
	const logoutHook = useLogout(navigate, gym);

	const handleLogout = useCallback(() => {
		return logoutHook.logout();
	}, [logoutHook]);

	const handleAccountSettingsClick = useCallback(() => {
		navigate(`../account-settings`);
	}, [navigate]);

	const handleGymClick = useCallback(() => {
		navigate(`../my-gym`);
	}, [navigate]);

	const handleMembershipClick = useCallback(() => {
		navigate(`../consumer-membership`);
	}, [navigate]);

	return (
		<StyledProfile>
			<div className="sb-profile-container">
				<ProfileBanner title="My Profile" picture={true} />
				<div className="sb-profile-container__body">
					<Heading3 className="sb-profile-container__body__title">Account</Heading3>
					<AccountCard
						onClick={handleAccountSettingsClick}
						customColor={false}
						icon={<KeyIcon />}
						header={"Account Settings"}
						text={"Manage profile information"}
					/>
					<AccountCard
						onClick={() => setOpenEditProfile(true)}
						customColor={false}
						icon={<PersonPlusIcon />}
						header={"Profile Settings"}
						text={"Manage email and phone number"}
					/>
					<AccountCard
						onClick={() => setOpenResetPassword(true)}
						customColor={false}
						icon={<PersonPlusIcon />}
						header={"Reset Password"}
						text={"Update your password"}
					/>
					<AccountCard
						onClick={handleGymClick}
						customColor={false}
						icon={<DumbellIcon />}
						header={"My Gym"}
						text={"About your gym, address, gym philosophy"}
					/>
					<AccountCard
						onClick={handleMembershipClick}
						customColor={false}
						icon={<StarIcon />}
						header={"Membership"}
						text={"Edit payment information, payment dates, receipts"}
					/>
					{/* <h1 className="sb-profile-container__body__title">Share the app</h1>
					<AccountCard
						onClick={() => {}}
						customColor={false}
						icon={<ShareIcon />}
						header={"Share the App"}
						text={"Share the app with friends"}
					/> */}
					<Button
						className="sb-profile-container__body__logout primary-outlined--delete"
						onClick={() => setShowConfirmDeleteModal(true)}
						uppercase
					>
						<Paragraph3>Delete Account</Paragraph3>
					</Button>
					<Button className="sb-profile-container__body__logout primary-outlined" onClick={handleLogout} uppercase>
						<Paragraph3>Log out</Paragraph3>
					</Button>
				</div>
			</div>
			<DeleteUserModal open={showConfirmDeleteModal} setOpen={setShowConfirmDeleteModal} />
			<Modal
				title={<Typography.Title level={3}>Update Password</Typography.Title>}
				open={openResetPassword}
				onCancel={() => setOpenResetPassword(false)}
				maskClosable={false}
				closable
				style={{ "--primary-color": primaryColor }}
				footer={[]}
				destroyOnClose
			>
				<UpdatePassword open={openResetPassword} onCancel={() => setOpenResetPassword(false)} />
			</Modal>
			<Modal
				open={openEditProfile}
				footer={null}
				title={<Typography.Title level={3}>Edit Profile</Typography.Title>}
				onCancel={() => setOpenEditProfile(false)}
				destroyOnClose
				maskClosable={false}
			>
				<EditProfile onCancel={() => setOpenEditProfile(false)} />
			</Modal>
		</StyledProfile>
	);
};

export default Profile;
