// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from "react";
import { DatePicker, Form } from "antd";
// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import StyledFormDateSelect from "./styles";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------

const FormDateSelect = ({ name, label, rules, className, disabled, ...rest }) => {
	const dateFormat = "ddd, MMM D";
	return (
		<StyledFormDateSelect className={`${className} sb-form-select`}>
			<Form.Item name={name} label={label} rules={rules} required={false}>
				<DatePicker
					style={{ width: "100%", height: "46px", borderRadius: "100px" }}
					format={dateFormat}
					disabled={disabled}
					{...rest}
				/>
			</Form.Item>
		</StyledFormDateSelect>
	);
};

export default FormDateSelect;

