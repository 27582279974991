import React, { useEffect, useState, useRef, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import MuxPlayer from "@mux/mux-player-react";
import ReactPlayer from "react-player/lazy";
import mux from "mux-embed";
import axios from "axios";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Col from "antd/lib/col";
import Divider from "antd/lib/divider";
import Row from "antd/lib/row";
import Slider from "antd/lib/slider";

import { userAtom } from "../../../globalAtoms";
import CoachSVG from "../../../images/coachIcon.svg";
import highVolumeSVG from "../../../images/highVolume.svg";
import homeIcon from "../../../images/homeIcon.svg";
import lowVolumeSVG from "../../../images/lowVolume.svg";
import MusicSVG from "../../../images/music.svg";
import PauseSVG from "../../../images/pause.svg";
import PlaySVG from "../../../images/play.svg";
import skipNextIcon from "../../../images/skip-next-white.svg";
import ajaxRoutes from "../../../utils/ajaxRoutes";
import Ajax from "../../../utils/api";
import AddPaymentMethodModal from "components/Modals/AddPaymentMethodModal";
import {
	useCreateCustomerMutation,
	useStripeCreateSetupIntentMutation,
	useCreateSubscriptionMutation,
	useGetDomainGymQuery,
	useStripeGetSubscriptionsForGymMutation,
	useLazyGetWorkoutNextMixQuery,
} from "store/api/api";
import { LoadingMask } from "components/Common/LoadingMask";
import { decodeString } from "utils";
import { isUserCreator } from "constants/ui";
import Layout from "./Layout";
import "./styles.scss";

function FullScreenWorkoutVideoMusicPlayer() {
	const navigate = useNavigate();
	const [state, setState] = useState({
		playing: true,
		musicVolume: 0.3,
		coachVolume: 0.8,
		played: 0,
		videoUrl: null,
		audioUrl: null,
		showing: true,
		mixTracksSeconds: 0,
		totalSeconds: [],
	});
	const [live, setLive] = useState(false);
	const [clientSecret, setClientSecret] = useState(null);
	const [setupIntentId, setSetupIntentId] = useState(null);
	const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);
	const [priceId, setPriceId] = useState(null);
	const [unitAmount, setUnitAmount] = useState(null);
	const [gymAccountId, setGymAccountId] = useState(null);
	const [playbackToken, setPlaybackToken] = useState(null);
	const [thumbnailToken, setThumbnailToken] = useState(null);
	const [playingMusic, setPlayingMusic] = useState(false);
	const [paymentRequired, setPaymentRequired] = useState(false);
	const [currentTrack, setCurrentTrack] = useState("");
	const [currentArtist, setCurrentArtist] = useState("");
	const [streamPlaybackId, setStreamPlaybackId] = useState(null);
	const [nextMix, setNextMix] = useState(null);
	const [userType, setUserType] = useState("consumer");
	const user = useRecoilValue(userAtom);
	const musicRef = useRef(null);
	const { workoutID } = useParams();
	const { gym } = useParams();
	const location = useLocation();
	const { data: domainGymData, isLoading: domainGymDataLoading } = useGetDomainGymQuery(gym);
	const [createCustomerTrigger] = useCreateCustomerMutation();
	const [createSetupIntentTrigger, createSetupIntentResponse] = useStripeCreateSetupIntentMutation();
	const [createSubscriptionTrigger, createSubscriptionResp] = useCreateSubscriptionMutation();
	const [getSubscriptionsForGymTrigger] = useStripeGetSubscriptionsForGymMutation();
	const [getWorkoutNextMixTrigger, getWorkoutNextMixResponse] = useLazyGetWorkoutNextMixQuery();
	const setUser = useSetRecoilState(userAtom);
	const internalPlayerRef = useRef(null);
	const videoRef = useRef(null);
	const livePlayerRef = useRef(null);
	const paneControlRef = useRef();

	const playedInPercents = useMemo(() => {
		return (state.played / state.totalSeconds) * 100;
	}, [state.played, state.totalSeconds]);

	useEffect(() => {
		async function startWorkout() {
			const body = {
				workout_id: workoutID,
				user_id: user?.id,
				gym_id: domainGymData?.id,
			};
			try {
				const jwt = localStorage.getItem("creator_jwt_token");
				const headers = {
					Authorization: jwt,
				};
				const response = await axios.post(ajaxRoutes.START_WORKOUT, body, { headers });
				if (response?.data?.workout) {
					setLive(location.pathname.includes("live"));
					const { workout } = response.data;
					let videoUrl = workout?.video_url;
					setStreamPlaybackId(workout?.playback_id);
					const lengthSplit = workout?.mix?.length?.split(":");
					const totalSeconds =
						lengthSplit && lengthSplit.length >= 3
							? +lengthSplit[0] * 60 * 60 + +lengthSplit[1] * 60 + +lengthSplit[2]
							: 0;
					const mixTracksSeconds = workout?.mix?.tracks?.map((track) => {
						const a = track.time.split(":");
						const currentValue = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
						return currentValue;
					});

					if (!videoUrl) {
						const { token, thumbnail_token, url } = await Ajax.call(ajaxRoutes.GET_MUX_VIDEO_URL(workout?.playback_id));
						videoUrl = url;
						setPlaybackToken(token);
						setThumbnailToken(thumbnail_token);
					}

					setState((prevState) => ({
						...prevState,
						videoUrl: videoUrl || null,
						workout: workout,
						audioUrl: workout?.mix?.url,
						mix: workout?.mix,
						mixTracksSeconds,
						totalSeconds,
					}));
				} else {
					navigate(`/${gym}/dashboard/on-demand`);
				}
			} catch (e) {
				console.debug("ERROR: ", e);
				if (e?.response?.status === 402) {
					await getSubscriptionInfo();
				} else {
					navigate(`/${gym}/dashboard/on-demand`);
				}
			}
		}

		if (!domainGymDataLoading) {
			startWorkout();
		}
	}, [domainGymDataLoading]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const associationsGymIds = user?.userAssociations?.map((a) => a.gym_id);
		const association = user?.userAssociations?.find((a) => a.gym_id === domainGymData?.id);

		if (associationsGymIds?.includes(domainGymData?.id) || user?.user_type === "admin") {
			setUserType(association?.type);
		}
	}, [user, domainGymData]);

	useEffect(() => {
		if (state.workout && state.mixTracksSeconds) {
			let currentIndex = 0;

			for (let i = 0; i < state.mixTracksSeconds.length; i++) {
				if (state.played >= state.mixTracksSeconds[state.mixTracksSeconds.length - 1]) {
					currentIndex = state.mixTracksSeconds.length - 1;
					break;
				}

				if (state.mixTracksSeconds[i] > state.played && i > 0) {
					currentIndex = i - 1;
					break;
				}
			}

			setCurrentTrack(state.workout.mix.tracks[currentIndex].title);
			setCurrentArtist(state.workout.mix.tracks[currentIndex].artist);
		}
	}, [state?.played, state?.mixTracksSeconds, state.workout, state.workout?.mix?.tracks]);

	useEffect(() => {
		if (createSubscriptionResp?.data?.id !== undefined) {
			window.location.reload();
		}
	}, [createSubscriptionResp]);

	useEffect(() => {
		const nextMix = getWorkoutNextMixResponse?.data?.mix;

		if (nextMix) {
			setNextMix(nextMix);
		}
	}, [getWorkoutNextMixResponse]);

	useEffect(() => {
		if (playedInPercents >= 85 && !nextMix) {
			getWorkoutNextMixTrigger(workoutID);
		}
	}, [getWorkoutNextMixTrigger, nextMix, playedInPercents, workoutID]);

	useEffect(() => {
		if (createSetupIntentResponse.isSuccess) {
			setClientSecret(createSetupIntentResponse.data.client_secret);
			setSetupIntentId(createSetupIntentResponse.data.id);
			setShowPaymentMethodModal(true);
			setPaymentRequired(true);
		}
	}, [createSetupIntentResponse]);

	const handleToggleMusic = () => {
		setPlayingMusic(!playingMusic);
	};

	const handleNativePlay = () => {
		if (!state.playing) {
			setState((prevState) => ({
				...prevState,
				playing: !state.playing,
				showing: state.playing,
			}));
		}
	};

	const handleNativePause = () => {
		if (state.playing) {
			setState((prevState) => ({
				...prevState,
				playing: !state.playing,
				showing: state.playing,
			}));
		}
	};

	const handleVolumeChange = (value, type) => {
		setState((prevState) => ({
			...prevState,
			[type === "music" ? "musicVolume" : "coachVolume"]: value / 100,
		}));
	};

	const handleSeekMouseUp = async (value) => {
		if (value === 100) {
			await handleMusicEnded();
		} else {
			const seconds = state.totalSeconds * (value / 100);
			setState((prevState) => ({ ...prevState, played: seconds }));
			musicRef.current.seekTo(value / 100);
		}
	};

	const handleTrackSkip = async () => {
		let nextValue = -1;

		if (state.played >= state.mixTracksSeconds[state.mixTracksSeconds.length - 1]) {
			await handleMusicEnded();
			return;
		}

		for (let i = 0; i < state.mixTracksSeconds.length; i++) {
			if (state.played < state.mixTracksSeconds[i]) {
				nextValue = state.mixTracksSeconds[i];
				break;
			}
		}

		if (nextValue !== -1) {
			setState((prevState) => ({ ...prevState, played: nextValue }));
			musicRef.current.seekTo(nextValue);
		}
	};

	const handleProgress = ({ played }) => {
		const seconds = state.totalSeconds * played;
		setState((prevState) => ({ ...prevState, played: seconds }));
	};

	const handleMusicEnded = async () => {
		let mix = nextMix;

		if (!nextMix) {
			const result = await getWorkoutNextMixTrigger(workoutID).unwrap();
			mix = result?.mix || state?.mix;
		}

		const lengthSplit = mix?.length?.split(":");
		const totalSeconds =
			lengthSplit && lengthSplit.length >= 3 ? +lengthSplit[0] * 60 * 60 + +lengthSplit[1] * 60 + +lengthSplit[2] : 0;
		const mixTracksSeconds = mix?.tracks?.map((track) => {
			const a = track.time.split(":");
			const currentValue = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
			return currentValue;
		});
		const workout = state.workout;
		workout.mix = mix;

		setState((prevState) => ({
			...prevState,
			workout,
			audioUrl: mix?.url,
			mix: mix,
			mixTracksSeconds,
			totalSeconds,
			played: 0,
		}));

		setNextMix(null);
	};

	const handleEnded = () => {
		setState((prevState) => ({
			...prevState,
			playing: false,
			seeking: false,
		}));
	};

	const renderTags = (tags) => {
		if (tags) {
			return tags.map((tag, index) => {
				const renderComma = index !== tags.length - 1;

				return (
					<span key={`tag-key-${index}`}>
						{tag}
						{renderComma ? "," : ""}
					</span>
				);
			});
		}
	};

	// PAYMENTS:
	const getSubscriptionInfo = async () => {
		const gymProductResponse = await getSubscriptionsForGymTrigger({
			gym_name: domainGymData?.name,
		}).unwrap();
		setPriceId(gymProductResponse?.default_price);
		setUnitAmount(gymProductResponse?.unit_amount);
		setGymAccountId(gymProductResponse?.metadata?.accountId);
		var customer_id = user?.customer_id;
		if (!user?.customer_id) {
			const token = localStorage.getItem("creator_jwt_token");
			const custResp = await createCustomerTrigger({
				body: { email: user.email_address, user_id: user.id, auth_token: token },
			});
			customer_id = custResp?.data?.id;
			setUser({ ...user, customer_id });
		}
		if (gymProductResponse?.data?.unit_amount === 0) {
			// User create subscription trigger here instead of direct lambda
			const body = {
				customer_id,
				price_id: gymProductResponse?.data?.default_price,
				account_id: gymProductResponse?.data?.metadata?.accountId,
				gym_id: domainGymData?.id,
			};

			await createSubscriptionTrigger(body);
		} else {
			await createSetupIntentTrigger({
				customer_id,
			});
		}
	};

	if (paymentRequired) {
		return (
			<div>
				<AddPaymentMethodModal
					subscribingToGym={{
						currentCustomerId: user.customer_id,
						priceId,
						accountId: gymAccountId,
						gymName: domainGymData?.name,
						price: unitAmount,
					}}
					afterSubscribe={() => window.location.reload()}
					setupIntentId={setupIntentId}
					open={showPaymentMethodModal}
					setOpen={setPaymentRequired}
					clientSecret={clientSecret}
					onClose={() => navigate(`/${gym}/dashboard`)}
				/>
			</div>
		);
	}

	if (!state.videoUrl) {
		return <LoadingMask />;
	}

	const onStart = () => {
		setPlayingMusic(true);
		const internalPlayer = videoRef.current.getInternalPlayer();
		internalPlayerRef.current = internalPlayer;

		if (!isUserCreator(userType)) {
			const initTime = mux.utils.now();
			mux.monitor(internalPlayerRef.current, {
				debug: false,
				data: {
					env_key: process.env.REACT_APP_MUX_ENV_KEY, // required
					player_name: "Web Player",
					player_init_time: initTime,
					viewer_user_id: `${state?.workout?.user_id || "Not Found"}`,
					experiment_name: "Dev Env Player",
					gym_id: `${state?.workout?.gym_id || "Not Found"}`,
					workout_id: `${state?.workout?.id || "Not Found"}`,
					sub_property_id: `${state?.workout?.id || "Not Found"}`,
					video_id: `${state?.workout?.id || "No ID Found"}`,
					video_title: `${state?.workout?.name || "No Title Found"}`,
					video_series: "Series",
					video_duration: `${state?.workout?.duration || "No Duration Found"}`,
					video_stream_type: "On Demand", // 'live' or 'on-demand'
				},
			});
		}
	};

	if (livePlayerRef.current) {
		livePlayerRef.current.volume = state.coachVolume;
	}
	return (
		<Layout
			isUserCreator={isUserCreator(userType)}
			Content={
				<div className="video-player">
					<div className="workout-name">
						<Link style={{ color: "#FFF", marginRight: 16 }} to={isUserCreator(userType) ? "../consumer" : ".."}>
							<ArrowLeftOutlined />
						</Link>
						{decodeString(state?.workout?.name)}
					</div>
					<div style={{ flex: 1 }}>
						<ReactPlayer
							style={{ display: "none" }}
							ref={musicRef}
							playing={playingMusic}
							volume={state.musicVolume}
							url={state.audioUrl}
							onProgress={handleProgress}
							onEnded={handleMusicEnded}
						/>

						{live && streamPlaybackId && playbackToken && thumbnailToken && (
							<MuxPlayer
								ref={livePlayerRef}
								streamType="on-demand"
								playbackId={streamPlaybackId}
								metadata={
									!isUserCreator(userType)
										? {
												video_id: streamPlaybackId,
												video_title: state?.workout.name || "No Title Found",
												viewer_user_id: user?.id || "Unknown User",
										  }
										: {}
								}
								tokens={{
									playback: playbackToken,
									thumbnail: thumbnailToken,
								}}
							/>
						)}
						{!live && (
							<ReactPlayer
								playing={state.playing}
								volume={state.coachVolume}
								url={state.videoUrl}
								ref={videoRef}
								controls={true}
								onStart={onStart}
								onPlay={handleNativePlay}
								onPause={handleNativePause}
								onEnded={handleEnded}
								height={"calc(100vh -165px)"}
								width={"100%"}
							/>
						)}
					</div>
				</div>
			}
			Sider={
				<div>
					<div ref={paneControlRef} className="music-right-pane-controls">
						<div className="music-right-pane-title">
							<div
								className="image-container"
								style={{
									backgroundImage: `url(${state?.workout?.mix?.image_large})`,
								}}
							>
								<div
									className="image-container__inner"
									style={{
										backgroundImage: `url(${state?.workout?.mix?.dj?.image_large})`,
									}}
								/>
								<div className="image-container__text" dangerouslySetInnerHTML={{ __html: state?.mix?.dj?.name }} />
							</div>
							<h1 dangerouslySetInnerHTML={{ __html: state?.mix?.title }} />
							<div className="tags">{renderTags(state?.mix?.tags)}</div>
						</div>
						<Divider />
						<div className="music-right-pane-title">
							<h1 dangerouslySetInnerHTML={{ __html: currentTrack }} />
							<p dangerouslySetInnerHTML={{ __html: currentArtist }} />
						</div>
						<div className="music-controls">
							<div className="home-button" onClick={() => navigate("/dashboard")}>
								<img src={homeIcon} alt="home" />
							</div>
							<div className="play-button">
								<div onClick={handleToggleMusic}>
									{playingMusic ? (
										<img src={PauseSVG} alt="pause" />
									) : (
										<img src={PlaySVG} style={{ filter: "invert(100%)" }} alt="play" />
									)}
								</div>
							</div>
							<div className="skip-button" onClick={() => handleTrackSkip()}>
								<img src={skipNextIcon} alt="skip" />
							</div>
						</div>
						<Slider className="music-seek" value={playedInPercents} onAfterChange={handleSeekMouseUp} />
						<Divider />
						<div>
							<Row>
								<Col span={8}>
									<h1 className="music-seek-title">
										<img src={CoachSVG} alt="coach" /> COACH
									</h1>
								</Col>
								<Col span={8} offset={8}>
									<h1 style={{ float: "right" }} className="music-seek-title">
										{Math.round(state.coachVolume * 100)}%
									</h1>
								</Col>
							</Row>
							<div className="music-seek-container">
								<img src={lowVolumeSVG} alt="low" />
								<Slider
									value={state.coachVolume * 100}
									className="music-seek volume-slider"
									onChange={(e) => handleVolumeChange(e, "coach")}
								/>
								<img src={highVolumeSVG} alt="high" />
							</div>
						</div>
						<Divider />
						<div>
							<Row>
								<Col span={8}>
									<h1 className="music-seek-title">
										<img src={MusicSVG} alt="music" /> MUSIC
									</h1>
								</Col>
								<Col span={8} offset={8}>
									<h1 style={{ float: "right" }} className="music-seek-title">
										{Math.round(state.musicVolume * 100)}%
									</h1>
								</Col>
							</Row>
							<div className="music-seek-container">
								<img src={lowVolumeSVG} alt="low" />
								<Slider
									value={state.musicVolume * 100}
									className="music-seek volume-slider"
									onChange={(e) => handleVolumeChange(e, "music")}
								/>
								<img src={highVolumeSVG} alt="high" />
							</div>
						</div>
					</div>
				</div>
			}
		/>
	);
}

export default FullScreenWorkoutVideoMusicPlayer;
