// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Form, Switch, Tooltip } from "antd";
// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
import { selectSelectedWorkout } from "store/selectors";
import { useGetCategoriesQuery, useGetDomainGymQuery } from "store/api/api";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { Button } from "components/Common/Button";
import { Paragraph2, Span4 } from "themes/default/_typography";
import { FormTagSelect } from "components/Common/Form/FormTagSelect";
import { FormInput } from "components/Common/Form/FormInput";
import { FormSelect } from "components/Common/Form/FormSelect";
import Toggle from "components/UI/Form/Toggle";
import DateTimeTimezone from "components/DateTimeTimezone";
// -----------------------------------------------------------------------------
// Utils and Styles
// -----------------------------------------------------------------------------
import StyledWorkoutDetailsForm from "./styles";
import { defaultTimezone, durationOptions, workoutTypes, workoutStatuses } from "helpers/constants";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);
// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const WorkoutDetailsForm = ({ nextStep, live, previousStep, values, loading }) => {
	const [form] = Form.useForm();
	const selectedWorkout = useSelector(selectSelectedWorkout);
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGymId = domainGymResponse.data?.id;
	const isPartnerGym = !!domainGymResponse.data?.is_partner_gym;

	const [scheduleRelease, setScheduleRelease] = useState(!!values.is_scheduled);
	const [active, setActive] = useState(values?.status === workoutStatuses.ACTIVE);
	const [isFree, setIsFree] = useState(!!values.is_free);
	const [currentDatetime, setCurrentDatetime] = useState(null);

	const { data: categoriesData } = useGetCategoriesQuery(domainGymId, {
		skip: !domainGymId,
	});

	const handleChangeActive = (checked, event) => {
		setActive(checked);
	};

	const handleChangeScheduling = (checked, event) => {
		setScheduleRelease(checked);
		if (checked && active) {
			setActive(false);
		}
	};

	const convertStartTime = (workoutDate, workoutTime) => {
		if (!workoutDate || !workoutTime) return null;
		const date = dayjs(workoutDate).format("YYYY-MM-DD");
		const startTime = dayjs.tz(`${date} ${workoutTime}`, "UTC").format("YYYY-MM-DD HH:mm:ss");

		return startTime;
	};

	const getPayload = () => {
		const { workoutCategories, workoutEquipment, workoutLength, workoutDate, workoutTime, workoutTimezone } =
			form.getFieldsValue();
		const payload = {
			workout_id: selectedWorkout?.id,
			categories: (workoutCategories ? workoutCategories?.join(",") : "")?.trim(),
			equipment: workoutEquipment ? workoutEquipment : "",
			start_time: scheduleRelease && !live ? convertStartTime(workoutDate, workoutTime) : null,
			is_free: isFree?.free && isPartnerGym ? 1 : 0,
			time_zone: scheduleRelease ? workoutTimezone : null,
			is_scheduled: scheduleRelease ? 1 : 0,
			type: scheduleRelease ? workoutTypes.SCHEDULED_ON_DEMAND : workoutTypes.ON_DEMAND,
			workoutDate,
			workoutTime,
			...(live
				? { duration: workoutLength ? workoutLength : null }
				: { status: active ? workoutStatuses.ACTIVE : workoutStatuses.INACTIVE }),
		};

		return payload;
	};

	const onFinish = () => {
		const payload = getPayload();
		nextStep(payload);
	};

	const onPreviousStep = () => {
		const payload = getPayload();
		previousStep(payload);
	};

	const workoutLength = values.duration ? values.duration : null;

	return (
		<StyledWorkoutDetailsForm>
			<div className="sb-workout-details-form">
				<Form
					data-id="workout-details-form"
					onFinish={onFinish}
					layout="vertical"
					form={form}
					initialValues={{
						workoutCategories: values?.categories
							? values.categories?.trim()?.split(",")?.filter(Boolean) ?? undefined
							: undefined,
						workoutLength,
						workoutEquipment: values.equipment,
						workoutTimezone: values.time_zone || defaultTimezone,
						workoutDate: values.workoutDate,
						workoutTime: values.workoutTime,
					}}
				>
					{categoriesData?.categories?.length > 0 && (
						<FormTagSelect
							name="workoutCategories"
							label="Workout Categories"
							placeholder="Enter Categories"
							options={categoriesData?.categories?.map((cat) => ({
								value: cat.name,
								id: cat.id,
							}))}
						/>
					)}
					{live && (
						<FormSelect
							name="workoutLength"
							placeholder="Workout Length"
							rules={[{ required: true, message: "This field is required" }]}
							label="Workout Length"
							options={durationOptions}
							showSearch={false}
						/>
					)}
					<div className="sb-workout-details-form__form__field">
						<FormInput
							name="workoutEquipment"
							placeholder="Workout Equipment"
							label={
								<span className="sb-workout-details-form__form__field__label">
									<span className="sb-workout-details-form__form__field__label__main">Equipment</span>
									<Span4 className="sb-workout-details-form__form__field__label__extra">(Separate With Commas)</Span4>
								</span>
							}
						/>
					</div>
					{!live && (
						<div className="sb-workout-details-form__form__activation-schedule">
							<div>
								<span
									className="sb-workout-details-form__form__field__label__main"
									style={{ color: scheduleRelease ? "grey" : "black" }}
								>
									Active
								</span>
								<Switch
									checked={active}
									disabled={scheduleRelease}
									onChange={(checked) => handleChangeActive(checked)}
								/>
							</div>
							<div>
								<span className="sb-workout-details-form__form__field__label__main">Schedule For Later?</span>
								<Tooltip title="Your workout will be activated for your members at the selected time">
									<Switch checked={scheduleRelease} onChange={(checked) => handleChangeScheduling(checked)} />
								</Tooltip>
							</div>
						</div>
					)}
					{scheduleRelease && (
						<div style={{ paddingTop: "8px" }}>
							<div className="sb-form-date-timezone">
								<DateTimeTimezone
									form={form}
									currentDatetime={currentDatetime}
									setCurrentDatetime={setCurrentDatetime}
								/>
							</div>
						</div>
					)}
					{isPartnerGym && (
						<div className="sb-workout-details-form__form__field">
							<Toggle
								initial={!!values.is_free}
								name="free"
								text="Free"
								updateToggles={setIsFree}
								tooltipTitle="This is only for use by API Partners."
							/>
						</div>
					)}
					<div className="sb-workout-details-form__form__actions">
						<Button
							onClick={onPreviousStep}
							className="sb-release-schedule-form__form__button secondary-filled"
							chevronLeft
							uppercase
						>
							<Paragraph2>Back</Paragraph2>
						</Button>
						<Button
							type="submit"
							loading={loading}
							disabled={loading}
							className="primary-filled"
							chevronRight
							uppercase
							data-id="sb-create-workout-submit-details"
						>
							<Paragraph2>Next</Paragraph2>
						</Button>
					</div>
				</Form>
			</div>
		</StyledWorkoutDetailsForm>
	);
};

export default WorkoutDetailsForm;

