import React from "react";

const PersonPlusIcon = (params) => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...params}>
		<path
			d="M19.9997 16C22.9463 16 25.333 13.6067 25.333 10.6667C25.333 7.71999 22.9463 5.33333 19.9997 5.33333C17.053 5.33333 14.6663 7.71999 14.6663 10.6667C14.6663 13.6067 17.053 16 19.9997 16ZM7.99967 13.3333V9.33333H5.33301V13.3333H1.33301V16H5.33301V20H7.99967V16H11.9997V13.3333H7.99967ZM19.9997 18.6667C16.4463 18.6667 9.33301 20.4467 9.33301 24V26.6667H30.6663V24C30.6663 20.4467 23.553 18.6667 19.9997 18.6667Z"
			fill="currentColor"
		/>
	</svg>
);

export default PersonPlusIcon;
