// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React from "react";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { Button } from "components/Common/Button";
import { Paragraph2 } from "themes/default/_typography";

// -----------------------------------------------------------------------------
// Utils and Styles
// -----------------------------------------------------------------------------
import StyledCreateWorkoutForm from "./styles";
import { useGetDomainGymQuery } from "store/api/api";
import { useRecoilValue } from "recoil";
import { userAtom } from "globalAtoms";
import { useParams } from "react-router";
import { validateRequiredEncodedLength } from "helpers/Form/commonFormValidations";
import InputField from "components/UI/Form/InputField";
import Label from "components/UI/Form/label";
import { Field, Form } from "react-final-form";
import { decodeString, encodeString } from "utils";
import { workoutTypes } from "helpers/constants";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const CreateWorkoutForm = ({ nextStep, values, loading }) => {
	const user = useRecoilValue(userAtom);
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGymId = domainGymResponse.data?.id;

	const handleFormSubmit = async (formObj) => {
		const { workoutTitle, workoutDescription, workoutCoach } = formObj;

		await nextStep({
			gym_id: domainGymId,
			user_id: user?.id,
			name: encodeString(workoutTitle),
			coach: workoutCoach?.trim(),
			description: encodeString(workoutDescription),
			is_free: 0,
			type: workoutTypes.ON_DEMAND,
		});
	};

	return (
		<StyledCreateWorkoutForm>
			<div className="sb-create-workout-form">
				<Form
					onSubmit={handleFormSubmit}
					layout="vertical"
					className="sb-create-workout-form__form"
					initialValues={{
						workoutTitle: decodeString(values.name),
						workoutDescription: decodeString(values.description),
						workoutCoach: values.coach,
					}}
				>
					{({ handleSubmit }) => {
						return (
							<form id="create-workout" onSubmit={handleSubmit}>
								<Label>Workout Title</Label>
								<Field
									component="input"
									name="workoutTitle"
									validate={(value) => validateRequiredEncodedLength(value, 255)}
								>
									{({ input, meta }) => <InputField data-id="workoutTitle" type="text" input={input} meta={meta} />}
								</Field>
								<Label>Workout Description</Label>
								<Field
									component="input"
									name="workoutDescription"
									validate={(value) => validateRequiredEncodedLength(value, 2048)}
								>
									{({ input, meta }) => (
										<InputField textarea={true} data-id="workoutDescription" type="text" input={input} meta={meta} />
									)}
								</Field>
								<Label>Workout Coach</Label>
								<Field
									component="input"
									name="workoutCoach"
									validate={(value) => validateRequiredEncodedLength(value, 255)}
								>
									{({ input, meta }) => <InputField data-id="workoutCoach" type="text" input={input} meta={meta} />}
								</Field>

								<Button
									type="submit"
									className="sb-create-workout-form__form__button primary-filled"
									chevronRight
									uppercase
									loading={loading}
									disabled={loading}
									data-id="sb-create-workout-submit-info"
								>
									<Paragraph2>Next</Paragraph2>
								</Button>
							</form>
						);
					}}
				</Form>
			</div>
		</StyledCreateWorkoutForm>
	);
};

export default CreateWorkoutForm;

