import styled from "styled-components";

const StyledEditWorkoutForm = styled.div`
	.sb-edit-workout-form {
		&__upload {
			margin: 32px 0;

			&__input {
				margin-bottom: 8px;
			}
		}

		&__form {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			gap: 8px;

			&__genres {
				width: 100%;

				&__title {
					margin-bottom: 24px;
				}
			}

			&__actions {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 32px;
				margin-top: 50px;
				@media (max-width: 968px) {
					flex-wrap: wrap-reverse;
				}
			}
		}
		.edit-time {
			display: grid;
			gap: 16px;
			grid-template-columns: 1fr 1fr;
			margin-top: 16px;
			width: 100%;
			.ant-form-item {
				margin-bottom: 0;
			}

			& > :first-child {
				grid-column: span 2;
			}

			& > :nth-child(2),
			& > :nth-child(3) {
				grid-column: span 1;
			}
		}
	}
`;

export default StyledEditWorkoutForm;

