import React from "react";
import ValidationError from "../ValidationError";
import { Input } from "antd";

import "./index.scss";
import countries from "./countries";
import usePrimaryColor from "helpers/hooks/usePrimaryColor";
import { Default as Theme } from "themes/default";

const options = countries.map((country) => (
	<option key={country.code} value={country.dial_code}>
		{country.flag} {country.code} {country.dial_code}
	</option>
));

const { Password, TextArea } = Input;
function InputField({
	input,
	meta,
	value: inputValue,
	type,
	textarea,
	placeholder,
	disabled,
	style,
	onKeyUp,
	phone,
	...others
}) {
	const primaryColor = usePrimaryColor();
	const value = (input?.value === "undefined" || !input?.value ? "" : input.value) || inputValue;
	if (type === "password") {
		return (
			<div style={{ "--primary-color": primaryColor }} className="input-field-wrapper">
				<Password
					onKeyUp={onKeyUp}
					disabled={disabled}
					placeholder={placeholder}
					className="input-field"
					size="large"
					value={value}
					{...input}
					{...others}
				/>
				<ValidationError meta={meta} />
			</div>
		);
	}
	return (
		<Theme>
			<div style={{ "--primary-color": primaryColor, ...style }} className="input-field-wrapper">
				{textarea ? (
					<TextArea
						onKeyUp={onKeyUp}
						disabled={disabled}
						placeholder={placeholder}
						value={value}
						rows={5}
						{...input}
						{...others}
					/>
				) : phone ? (
					<div style={{ display: "flex", gap: "8px" }}>
						<select
							defaultValue="+1"
							style={{
								height: "46px",
								borderRadius: "100px",
								background: "#ffffff",
								boxSizing: "border-box",
								border: "1px solid #e3e5e7",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								padding: "8px",
								margin: "0",
							}}
						>
							{options}
						</select>
						<Input
							onKeyUp={onKeyUp}
							disabled={disabled}
							placeholder={placeholder}
							size="large"
							// className="input-field"
							type={type}
							value={value}
							{...input}
							{...others}
						/>
					</div>
				) : (
					<Input
						onKeyUp={onKeyUp}
						disabled={disabled}
						placeholder={placeholder}
						size="large"
						// className="input-field"
						type={type}
						value={value}
						{...input}
						{...others}
					/>
				)}

				<ValidationError meta={meta} />
			</div>
		</Theme>
	);
}

export default InputField;
